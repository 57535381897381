import { useState, useEffect } from 'react';

import { ATL_GENERAL } from '@confluence/web-item-location';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { usePageState } from '@confluence/page-context';

import { useWebPanelLocation } from './useWebPanelLocation';

export const useATLGeneralWebPanelListener = () => {
	const [webPanelHeight, setWebPanelHeight] = useState(0);
	const [{ contentId, spaceKey }] = usePageState();

	const { panels } = useWebPanelLocation({
		location: ATL_GENERAL,
		fetchPolicy: 'cache-first',
		spaceKey,
		contentId,
	});
	const isLivePage = useIsCurrentPageLive();

	const isATLGeneralWebPanelFixed = panels.length > 0 && !isLivePage;

	useEffect(() => {
		const listener = () => {
			const atlGeneralWebPanelHeight = parseInt(
				getComputedStyle(document.documentElement).getPropertyValue(
					'--atl-general-webpanel-height',
				),
			);
			setWebPanelHeight(atlGeneralWebPanelHeight);
		};

		if (isATLGeneralWebPanelFixed) {
			document.addEventListener('atlGeneralWebPanelHeightUpdated', listener);
		}

		return () => {
			if (isATLGeneralWebPanelFixed) {
				document.removeEventListener('atlGeneralWebPanelHeightUpdated', listener);
			}
		};
	}, [isATLGeneralWebPanelFixed]);

	return {
		isATLGeneralWebPanelFixed,
		atlGeneralWebPanelHeight: webPanelHeight,
	};
};
