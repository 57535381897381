import gql from 'graphql-tag';

import { contentAppearancePublishedFragment } from '@confluence/content-appearance/entry-points/fragments';

import { labelsFragment } from './labelsFragment.fragment';

export const contentUnifiedContentFragment = gql`
	fragment contentUnifiedContentFragment on Content {
		id
		status
		type
		subType
		title
		space {
			id
			key
			alias
		}
		version {
			confRev
			number
		}
		properties(keys: ["cover-picture-id-published", "editor"]) {
			nodes {
				id
				key
				value
				version {
					number
				}
			}
		}
		pageTitlePublished: properties(key: "page-title-property-published")
			@include(if: $useNewContentTopper) {
			nodes {
				id
				key
				value
				version {
					number
				}
			}
		}
		metadata {
			lastModifiedDate
			frontend {
				fabricEditorSupported
				coverPictureWidth @include(if: $useNewContentTopper)
			}
		}
		macroRenderedOutput @include(if: $isSSR) {
			key
			value {
				value
				representation
				webresource {
					tags {
						js
						css
					}
				}
			}
		}
		body {
			dynamic {
				representation
				value
				webresource {
					superbatch {
						tags {
							js
							css
						}
					}
					tags {
						js
						css
					}
				}
			}
		}
		...contentAppearancePublishedFragment
		...labelsFragment
	}
	${contentAppearancePublishedFragment}
	${labelsFragment}
`;
