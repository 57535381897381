import gql from 'graphql-tag';

import { contentUnifiedContentFragment } from './fragments/contentUnifiedContentFragment.fragment';
import { contentUnifiedSpaceFragment } from './fragments/contentUnifiedSpaceFragment.fragment';

export const ContentUnifiedQuery = gql`
	query ContentUnifiedQuery(
		$contentId: ID!
		$spaceKey: String = null
		$versionOverride: Int
		$isSSR: Boolean = false
		$useNewContentTopper: Boolean = false
		$preloadLabels: Boolean = false
	) @SLA @allowHigherTimeout {
		content(id: $contentId, version: $versionOverride) {
			nodes {
				...contentUnifiedContentFragment
			}
		}
		space(key: $spaceKey) {
			...contentUnifiedSpaceFragment
		}
	}
	${contentUnifiedContentFragment}
	${contentUnifiedSpaceFragment}
`;
