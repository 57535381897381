import { useContext } from 'react';
import { useQuery } from 'react-apollo';

import { getSingleParam, RoutesContext } from '@confluence/route-manager';

import { WebPanelLocationQuery } from './WebPanelLocationQuery.graphql';
import type {
	WebPanelLocationQuery as WebPanelLocationQueryType,
	WebPanelLocationQueryVariables as WebPanelLocationQueryVariablesType,
} from './__types__/WebPanelLocationQuery';
import type { WebPanel } from './WebPanelLocation';

export const useWebPanelLocation = ({
	spaceKey,
	contentId,
	location,
	fetchPolicy = 'cache-and-network',
	onCompleted,
}: {
	spaceKey?: string;
	contentId?: string;
	location: string;
	fetchPolicy?: 'cache-first' | 'cache-and-network' | 'network-only';
	onCompleted?: () => void;
}) => {
	const { getQueryParams } = useContext(RoutesContext);
	const queryParams = getQueryParams();

	const spaceIdentifier =
		spaceKey || getSingleParam(queryParams, 'spaceKey') || getSingleParam(queryParams, 'key');
	const contentIdentifier = contentId || getSingleParam(queryParams, 'pageId');

	const variables = contentIdentifier
		? { contentId: contentIdentifier, location }
		: { spaceKey: spaceIdentifier, location };

	const { loading, data, error } = useQuery<
		WebPanelLocationQueryType,
		WebPanelLocationQueryVariablesType
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		WebPanelLocationQuery,
		{
			variables,
			fetchPolicy,
			onCompleted,
		},
	);

	const panels = (data?.webPanels || []).filter((panel) => panel) as WebPanel[];

	return {
		panels,
		loading,
		error,
	};
};
