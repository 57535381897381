import { requireLegacyWRM } from '@confluence/wrm';
import { fg } from '@confluence/feature-gating';

export function evalLegacyConnectInlineScripts(container) {
	// Connect macros have an inline script that doesn't work automatically when
	// rendered in react using dangerouslySetInnerHTML. This script evaluates any
	// connect macro.
	if (!container) {
		return Promise.reject(new Error('evalLegacyConnectInlineScripts missing container argument'));
	}

	return new Promise((resolve) => {
		const connectScripts = Array.from(
			container.querySelectorAll('script.ap-iframe-body-script'),
		) as HTMLElement[];
		const macroScripts = Array.from(
			container.querySelectorAll('script.cc-macro-body-script'),
		) as HTMLElement[];

		const allScripts = connectScripts.concat(macroScripts);

		if (allScripts.length > 0) {
			// Download the connect web resources required to render an add-on before evaluating the inline scripts.
			requireLegacyWRM(
				[
					// ensure navigator-context misc dep is loaded before navigator-context
					'wr!confluence.web.resources:querystring',
					// resource to provide the context value of AP.navigator.getLocation
					'wr!confluence.web.resources:navigator-context',
					'wr!com.atlassian.plugins.atlassian-connect-plugin:confluence-atlassian-connect-resources-v5',
				],
				() => {
					allScripts.forEach((elementScript) => {
						if (fg('confluence_connect_indirect_eval')) {
							/**
							 * When evaluating the connect scripts, the eval function inherits the context in which is it called.
							 *
							 * This means that the eval function will have access to the variables and functions
							 * in the current scope. One of these variables is the `require` passed in from Atlaspack's module system.
							 *
							 * Since the eval scripts rely on AMD's require being globally available, and Atlaspack
							 * is now shadowing that variable, we need to evaluate the scripts in the global scope.
							 *
							 * We can do this using "indirect" eval, see the MDN docs for more info:
							 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/eval#direct_and_indirect_eval
							 *
							 * Assigning the eval function to a variable is enough to trigger the indirect eval behavior,
							 * and the scripts will be able to access the global require.
							 */
							const evalInGlobalScope = eval;
							evalInGlobalScope(elementScript.innerHTML);
						} else {
							eval(elementScript.innerHTML);
						}
					});
					resolve(null);
				},
				() => {
					if (process.env.NODE_ENV !== 'production') {
						// eslint-disable-next-line no-console
						console.error('Connect add-on cannot be initialized when WRM is disabled.');
					}
				},
			);
		} else {
			resolve(null);
		}
	});
}
