import type { ReactElement } from 'react';

import type { ExperienceAttributes, ExperienceEvent } from '@confluence/experience-tracker';

import { useDeepCompareEffect } from '../useDeepCompareEffect';
import { getMacroExperienceTracker } from '../MacroExperienceTracker';

export type MacroExperienceStartProps = {
	extensionKey?: string;
	name: string;
	mode: string;
	timeout: number;
	attributes?: ExperienceAttributes;
	collect?(events: ExperienceEvent[], experience): void;
	onSuccess?(): void;
	children?: ReactElement | null;
	contentId: string;
};

export function MacroExperienceStart({
	attributes,
	children = null,
	contentId,
	extensionKey,
	mode,
	name,
	timeout,
}: MacroExperienceStartProps) {
	useDeepCompareEffect(() => {
		getMacroExperienceTracker(mode, contentId).start({
			attributes,
			name,
			timeout,
			id: (attributes?.macroId as string) || contentId,
		});
	}, [attributes, contentId, extensionKey, mode, name, timeout]);

	// https://stackoverflow.com/questions/54905376
	return children as ReactElement<any>;
}
