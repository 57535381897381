export class SSRDisableHydrationRecorder {
	private disableHydration: boolean;

	constructor() {
		this.disableHydration = false;
	}

	public setDisableHydration(disable: boolean): void {
		this.disableHydration = disable;
	}

	public getDisableHydration(): boolean {
		return this.disableHydration;
	}
}
