import gql from 'graphql-tag';

export const labelsFragment = gql`
	fragment labelsFragment on Content {
		labels(orderBy: { sortField: LABELLING_CREATIONDATE, direction: ASCENDING })
			@include(if: $preloadLabels) {
			count
			nodes {
				id
				label
				name
				prefix
			}
		}
		operations @include(if: $preloadLabels) {
			operation
			targetType
		}
	}
`;
