export class SSRContentStateManager {
	private contentStateData: any = null;

	setContentStateData(data: any) {
		this.contentStateData = data;
	}

	getContentStateData() {
		return this.contentStateData;
	}
}
