export class EditExperienceHandler {
	private isEditExperience: boolean = false;
	private disableAfterPaintOptimization: boolean = false;

	public setEditExperience(isEditExperience: boolean): void {
		this.isEditExperience = isEditExperience;
	}
	public setDisableAfterPaintOptimization(disableAfterPaintOptimization: boolean): void {
		this.disableAfterPaintOptimization = disableAfterPaintOptimization;
	}
	public getShouldDisableAfterPaintOptimization(): boolean {
		return this.isEditExperience && this.disableAfterPaintOptimization;
	}
}
