import { createContext } from 'react';

import { UserTypes } from './UserTypes';

export type ExternalShareContextType = {
	isExternalShareRequest: boolean;
	userType: UserTypes;
};

export const ExternalShareContext = createContext<ExternalShareContextType>({
	isExternalShareRequest: false,
	userType: UserTypes.ANONYMOUS,
});
