import gql from 'graphql-tag';

import { contentUnifiedContentFragment } from './fragments/contentUnifiedContentFragment.fragment';
import { contentUnifiedSpaceFragment } from './fragments/contentUnifiedSpaceFragment.fragment';

//CONTEXT: the API Platform Team is working on switching to Canonical APIs to improve latency times.
// The GraphQL fields for Confluence page (pageV2) and blog post (blogPostV2) are different. ATM ContentUnifiedQuery is
// used to query data for both page and blog post, we can’t simply rewrite the query to use pageV2. I created a
// ContentBlogUnifiedQuery which is identical to the ContentUnifiedQuery but only gets called for blog posts so we won't
// send this query to the cc-graphql rewrite service.
export const ContentBlogUnifiedQuery = gql`
	query ContentBlogUnifiedQuery(
		$contentId: ID!
		$spaceKey: String = null
		$versionOverride: Int
		$isSSR: Boolean = false
		$useNewContentTopper: Boolean = false
		$preloadLabels: Boolean = false
	) @SLA {
		content(id: $contentId, version: $versionOverride) {
			nodes {
				...contentUnifiedContentFragment
			}
		}
		space(key: $spaceKey) {
			...contentUnifiedSpaceFragment
		}
	}
	${contentUnifiedContentFragment}
	${contentUnifiedSpaceFragment}
`;
