import gql from 'graphql-tag';

export const contentUnifiedSpaceFragment = gql`
	fragment contentUnifiedSpaceFragment on Space {
		id
		theme {
			name
			themeKey
		}
		lookAndFeel {
			content {
				screen {
					background
					backgroundColor
					backgroundImage
					backgroundPosition
					backgroundSize
					backgroundRepeat
					backgroundOrigin
					backgroundClip
					backgroundAttachment
					backgroundBlendMode
					layer {
						background
						backgroundColor
						backgroundImage
						backgroundPosition
						backgroundSize
						backgroundRepeat
						backgroundOrigin
						backgroundClip
						backgroundAttachment
						backgroundBlendMode
						width
						height
					}
					gutterTop
					gutterRight
					gutterBottom
					gutterLeft
				}
				container {
					background
					backgroundColor
					backgroundImage
					backgroundPosition
					backgroundSize
					backgroundRepeat
					backgroundOrigin
					backgroundClip
					backgroundAttachment
					backgroundBlendMode
					padding
					borderRadius
				}
			}
		}
		settings {
			routeOverrideEnabled
		}
		currentUser {
			isAnonymouslyAuthorized
		}
	}
`;
