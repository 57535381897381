export enum UserTypes {
	// Anonymous users, not logged in
	ANONYMOUS = 'anonymous',
	// Logged in, not Confluence user - member in the org
	ORG_MEMBER = 'org-member',
	// Logged in, Licensed Confluence user
	CONFLUENCE_USER = 'confluence-user',
	// Logged in, Atlassian user not in the org
	ATLASSIAN_USER = 'atlassian-user',
}
